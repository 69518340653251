import React from "react";
import { Link } from "react-router-dom";

import price1 from "../media/images/25-din.png"
import price2 from "../media/images/animal.png"
import price3 from "../media/images/kgf.png"

const PriceComponent = () => {
  return (
      <div id="price">
        <div>
      <div className="our-price">
        <div>
          <div className="our-price-heading">Our <span>Price</span> </div>
          <div className="our-price-head-text">
            One step to viral your brand
          </div>

          <div className="our-price-col">
            <div className="our-price-col-1">

                <div class="flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">


          <div class="flip-card-front-heading">MONTHLY POSTER</div>
          <div class="flip-card-front-img"><img title="memer" src={price1} alt="memer" /></div>
          <div class="flip-card-front-button">view now</div>

          </div>
          <div class="flip-card-back">

            
          <div class="flip-card-back-heading">₹ 999 / month</div>
          <div class="flip-card-back-points">
            <menu>
            <li>30 Meme Poster</li>
            <li>Daily Delivery</li>
            <li>Trending Content</li>
            <li>Current Affairs</li>
            <li>Movies, Cricket Web series, Politics & Much more</li>

            </menu>
          </div>

          <div class="flip-card-back-buttons">
            <Link title="contact"  to="https://wa.me/+919658139139?text=Hello ! I'm Interested in Memes MONTHLY POSTER package. Please contact me." target='_blank'>Book Now</Link>
            <Link title="contact" to='tel:+91-9658-139-139' target='_blank'>Call Now</Link>
          </div>
            
            
          </div>
        </div>
      </div>
                
            </div>
            <div className="our-price-col-2">

                <div class="flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            
          <div class="flip-card-front-heading">
            MONTHLY REEL (popular)
            {/* <br />
            <span>(Popular)</span> */}
            </div>
          <div class="flip-card-front-img flip-card-front-img-2"><img title="memer" src={price2} alt="memer" /></div>
          <div class="flip-card-front-button">view now</div>

          </div>
          <div class="flip-card-back">
            {/* Price List 2 */}
          <div class="flip-card-back-heading">₹ 2,499 / month</div>
          <div class="flip-card-back-points">
            <menu>
            <li>30 Meme Reel</li>
            <li>Can be used in Youtube Shorts also</li>
            <li>Daily Delivery</li>
            <li>Trending Content</li>
            <li>Current Affairs</li>
            <li>Movies, Cricket Web series, Politics & Much more</li>

            </menu>
          </div>

          <div class="flip-card-back-buttons">
            <Link title="contact"  to="https://wa.me/+919658139139?text=Hello ! I'm Interested in Memes MONTHLY REEL package. Please contact me." target='_blank'>Book Now</Link>
            <Link title="contact" to='tel:+91-9658-139-139' target='_blank'>Call Now</Link>
          </div>
            
          </div>
        </div>
      </div>
                
            </div>
            <div className="our-price-col-3">

                <div class="flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            
          <div class="flip-card-front-heading">MONTHLY COMBO</div>
          <div class="flip-card-front-img"><img title="memer" src={price3} alt="memer" /></div>
          <div class="flip-card-front-button">view now</div>

          </div>
          <div class="flip-card-back">

            
          <div class="flip-card-back-heading">₹ 2,999 / month</div>
          <div class="flip-card-back-points">
            <menu>
            <li>30 Meme Poster + 30 Meme Reel</li>
            <li>Can be used in Youtube Shorts also</li>
            <li>Daily Delivery</li>
            <li>Trending Content</li>
            <li>Current Affairs</li>
            <li>Movies, Cricket Web series, Politics & Much more</li>

            </menu>
          </div>

          <div class="flip-card-back-buttons">
            <Link title="contact"  to="https://wa.me/+919658139139?text=Hello ! I'm Interested in Memes MONTHLY COMBO package. Please contact me." target='_blank'>Book Now</Link>
            <Link title="contact" to='tel:+91-9658-139-139' target='_blank'>Call Now</Link>
          </div>
            
            
          </div>
        </div>
      </div>
                
            </div>
          </div>


        </div>
      </div>
    </div>
    {/* <div className="price">
      <div>
        <div className="price-container">
        <div className="price-container-col">
        <div className="price-container-col-heading-1">Title</div>
        <div className="price-container-col-price">Rs. 999 <span>/month</span></div>
        <div className="price-container-col-points">
          <div>✔ Lorem ipsum dolor sit amet.</div>
          <div>✔ Lorem ipsum dolor sit.</div>
          <div>✔ Lorem ipsum dolor sit amet.</div>
          <div>✔ Lorem, ipsum dolor.</div>
          <div>✔ Lorem ipsum dolor sit amet consectetur.</div> 
        </div>
        <div className="price-container-col-button">Buy</div>

        </div>

        </div>
      </div>
    </div> */}
    </div>
  );
};

export default PriceComponent;
