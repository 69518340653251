import React from "react";
import Slider from "react-slick";
import meme01 from "../media/images/memes-images/meme (1).jpeg";
import meme1 from "../media/images/memes-images/meme (1).jpg";
import meme2 from "../media/images/memes-images/meme (2).jpg";
import meme3 from "../media/images/memes-images/meme (3).jpg";
import meme4 from "../media/images/memes-images/meme (4).jpg";
import meme5 from "../media/images/memes-images/meme (5).jpg";
import meme6 from "../media/images/memes-images/meme (6).jpg";

const SliderComponent = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
      <div className="slider-component" id="work">
        
      <div className="slider-heading">Our <span>Work</span></div>
      
      <div className="slider-container">
        <Slider {...settings}>
          <div>
            {/* <h3>1</h3> */}
            <img title="meme" src={meme01} alt="meme" />
          </div>

          <div>
            <img title="meme" src={meme1} alt="meme" />
          </div>

          <div>
            <img title="meme" src={meme2} alt="meme" />
          </div>
          <div>
            <img title="meme" src={meme3} alt="meme" />
          </div>
          <div>
            <img title="meme" src={meme4} alt="meme" />
          </div>
          <div>
            <img title="meme" src={meme5} alt="meme" />
          </div>
          <div>
            <img title="meme" src={meme6} alt="meme" />
          </div>
        </Slider>
      </div>
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
    </div>
  );
};

export default SliderComponent;
