import React from 'react'
import { Link } from 'react-router-dom'
// import logo from "../media/images/logo.gif"
// import logo from "../media/images/LOL-Legendrs.png"
import logo from "../media/images/LOL-Legends-Black.png"
// import logo from "../media/images/logo-black.gif"

const Navbar = () => {
  return (
    <div id='home'>
        <div className="navbar">
            <div>

        {/* <div className="navbar-logo">Meme Magic Studios</div> */}
        {/* <div className="navbar-logo">LOL Legends</div> */}
        <div className="navbar-logo">
          <img title='logo' src={logo} alt="logo" />
        </div>
        
        <div className="navbar-menu">
            <a title="link" href='#home'>HOME</a>
            <a title="link" href='#price'>PACKAGES</a>
            <a title="link" href='#about'>ABOUT US</a>
            <a title="link" href='#work'>GALLERY</a>
            <a title="link" href='#faq'>FAQS</a>
        </div>
        
        <Link title='call button' to='tel:+91-9658-139-139' target='_blank' className="navbar-button">CALL NOW</Link>

            </div>
        </div>
    </div>
  )
}

export default Navbar