import React from 'react';
import img from "../media/images/drake-bg.png"
import { Link } from 'react-router-dom';
import { TypeAnimation } from "react-type-animation";

const Header = () => {
  return (
    <div>
        <div className="header">
            <div>
                <div className="header-col-1">
        <h1 className="header-heading-1">THE MEME <br /> AGENCY</h1>

        {/* <div className="header-heading-1">The <span>MEME</span> <br /> Store</div> */}
        {/* <div className="header-heading-2">Viral your Brand with us</div> */}
        {/* <div className="header-heading-2">WE'RE HERE TO VIRAL YOUR BRAND</div> */}

        <div className="header-heading-2">
        <TypeAnimation
                    sequence={[
                      "WE'RE HERE TO VIRAL YOUR BRAND",
                      2500,
                      "Crafting Memes to Make Your Brand Go Viral",
                      2500,
                      "Where Creativity Meets Comedy",
                      2500,
                      "Turning Ideas into Internet Gold",
                      2500,
                      "Unleash the Power of Memes ",
                      2500,
                      "Your Gateway to Memetic Success",
                      2500,
                      "Elevate Your Online Presence with Memes",
                      2500,
                      "From Concept to LOL",
                      2500,
                      "Bringing Memes to the Masses",
                      2500,
                    ]}
                    speed={50}
                    repeat={Infinity}
                  />
        </div>

        <div className="header-button"><Link title='call now' to="https://wa.me/+919658139139?text=Hello ! I'm Interested in Memes Service. Please contact me." target='_blank'>BOOK NOW</Link></div>

            </div>
                <div className="header-col-2">
                    <img title='drake' src={img} alt="drake" />
                </div>
            </div>
        </div>
    </div>
  )
}

export default Header