import React from "react";
import img from "../media/images/happy-bean.png";
import { Link } from "react-router-dom";
import icon from "../media/images/correct.png"

const WhyUsComponent = () => {
  return (
    <div className="why-us">
      <div className="why-us-col-1-heading-1">
        Welcome to <span>LOL Legends</span>{" "}
      </div>
      <div className="why-us-col-content">
        <div className="why-us-col-2">
          <img title="Mr. Bean" src={img} alt="Mr. Bean" />
        </div>

        <div className="why-us-col-1">
          <div className="why-us-col-1-text">
            {/* The Meme Agency, where humor meets innovation to create
            unforgettable digital experiences. */}
             At LOLLegends, we specialize in
            crafting hilarious and engaging memes that captivate audiences and
            elevate brands to new heights of online success.
            {/* <span> With our team of creative masterminds, we transform ideas into viral sensations, ensuring your message resonates in the fast-paced world of internet culture. From concept to execution, we're dedicated to helping you leave a lasting impression and stand out in the digital landscape. Join us on a journey where laughter reigns supreme, and legends are made, only at LOLLegends - The Meme Agency.</span> */}
          </div>
          <div className="why-us-col-1-all-points">

            <div className="why-us-col-1-point">
              <div className="why-us-col-1-point-icon"><img src={icon} alt="icon" title="icon" /></div>
              <div className="why-us-col-1-point-text">We bring laughter to your brand.</div>
            </div>

            <div className="why-us-col-1-point">
              <div className="why-us-col-1-point-icon"><img src={icon} alt="icon" title="icon" /></div>
              <div className="why-us-col-1-point-text">Turning humor into engagement.</div>
            </div>

            <div className="why-us-col-1-point">
              <div className="why-us-col-1-point-icon"><img src={icon} alt="icon" title="icon" /></div>
              <div className="why-us-col-1-point-text">We know what makes the internet laugh.</div>
            </div>

            <div className="why-us-col-1-point">
              <div className="why-us-col-1-point-icon"><img src={icon} alt="icon" title="icon" /></div>
              <div className="why-us-col-1-point-text">Boosting your online presence with humor.</div>
            </div>

            <div className="why-us-col-1-point">
              <div className="why-us-col-1-point-icon"><img src={icon} alt="icon" title="icon" /></div>
              <div className="why-us-col-1-point-text">Your brand’s new best friend for funny content.</div>
            </div>

            {/* <div className="why-us-col-1-point">
              <div className="why-us-col-1-point-icon"><img src={icon} alt="icon" title="icon" /></div>
              <div className="why-us-col-1-point-text">Join us and become a legend in the meme world.</div>
            </div> */}
            
          </div>
          <div className="why-us-col-1-button">
            <Link
              title="whatsapp button"
              to="https://wa.me/+919658139139?text=Hello ! I'm Interested in Memes Service. Please contact me."
              target="_blank"
            >
              Book Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUsComponent;
