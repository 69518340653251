import React, { useState } from 'react'

const FaqComponent = () => {
    const [show, setShow] = useState(false)
  return (
    <div>
        <div className="faq" id='faq'>
        <div className="faq-head">
        <div className="faq-heading-text">Frequently Asked  <span>Questions </span></div>
        <div className="faq-heading-icon" onClick={e=>setShow(!show)}>
            {
                show?
                <i className="fa-regular fa-rectangle-xmark"></i>
                :
                <i className="fa-regular fa-square-caret-left"></i>
            }
        </div>

        </div>
        {
                show?
        <div className="faq-content">
            
            <div className="faq-ques">Ques.	What exactly does LOLLegends do?</div>
            <div className="faq-ans"><span>Ans. </span>LOLLegends is a premier meme agency specializing in creating and distributing custom memes for brands and individuals. We harness the power of humor and internet culture to help our clients stand out in the digital world.</div>
            
            <div className="faq-ques">Ques. How does the meme creation process work? </div>
            <div className="faq-ans"><span>Ans. </span>Our process begins with understanding your brand and target audience. We then brainstorm creative meme concepts, design the memes, and finally distribute them across relevant platforms to maximize engagement.</div>
            
            <div className="faq-ques">Ques. Can you customize memes to fit our brand's tone and voice? </div>
            <div className="faq-ans"><span>Ans. </span>Absolutely! We work closely with each client to ensure that the memes we create align with their brand identity and messaging. Your input is invaluable in shaping the final product.</div>
            
            <div className="faq-ques">Ques. What platforms do you distribute memes on? </div>
            <div className="faq-ans"><span>Ans. </span>We distribute memes across various social media platforms, including but not limited to Instagram, Twitter, Facebook, TikTok, and Reddit. Our goal is to reach your target audience wherever they spend their time online.</div>
            
            <div className="faq-ques">Ques. How do you measure the success of memes? </div>
            <div className="faq-ans"><span>Ans. </span>We track key metrics such as engagement, shares, likes, comments, and impressions to gauge the performance of our memes. Additionally, we provide detailed analytics reports to our clients, allowing them to assess the impact of our efforts.</div>
            
            <div className="faq-ques">Ques. Is there a minimum commitment or contract length</div>
            <div className="faq-ans"><span>Ans. </span>We offer flexible engagement options tailored to each client's needs. Whether you're looking for a one-time meme campaign or ongoing meme management, we can accommodate your requirements.</div>
            
            <div className="faq-ques">Ques. How can we get started with LOLLegends? </div>
            <div className="faq-ans"><span>Ans. </span>Getting started is easy! Simply reach out to us via our website or contact information provided, and one of our meme experts will be in touch to discuss your project and tailor a solution that meets your needs</div>
            
            {/* <div className="faq-ques">Ques. </div>
            <div className="faq-ans"><span>Ans. </span></div> */}
            

            </div>
        :""}
        </div>
    </div>
  )
}

export default FaqComponent