import React from 'react'
import MemeCategory from '../components/MemeCategory'

const OtherPage = () => {
  return (
    <div>
      <MemeCategory/>
    </div>
  )
}

export default OtherPage