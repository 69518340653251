import React from "react";
import icon from "../media/images/correct.png"

const HowComponent = () => {
  
  return (
    <div>
   
    <div id="welcome" className="welcome-comp">
      <div>
        <div className="welcome-comp-heading">
          How <span> LOL Legends </span> works?
        </div>
        <div className="welcome-comp-content">
        <div className="welcome-comp-content-col-1">
        </div>
        <div className="welcome-comp-content-col-2">

        <div className="welcome-comp-content-col-2-points">
            <h2><span><img title="icon" src={icon} alt="icon" /></span> <span>Check examples by searching your category scrolling this page </span></h2>
            <h2><span><img title="icon" src={icon} alt="icon" /></span> <span>Select any package given on this page and proceed to checkout</span></h2>
            <h2><span><img title="icon" src={icon} alt="icon" /></span> <span>Fill all your details and instructions on checkout page and upload logo</span></h2>
            <h2><span><img title="icon" src={icon} alt="icon" /></span> <span>Click on Buy Now button and make your order</span></h2>
            <h2><span><img title="icon" src={icon} alt="icon" /></span> <span>Receive a whatsapp text from us about order on same day</span></h2>
            <h2><span><img title="icon" src={icon} alt="icon" /></span> <span>Start Receiving delivery from the next day on your emial Id.</span></h2>
            <h2><span><img title="icon" src={icon} alt="icon" /></span> <span>For all other doubts check our FAQ section below</span></h2>
        </div>
        </div>

        {/* <div className="welcome-comp-content-col-2">
        <div className="welcome-comp-content-col-2-heading-0">Small Heading</div>
        <div className="welcome-comp-content-col-2-text">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate sed consequatur laudantium quibusdam eum dolore tenetur. Neque quidem autem officia.
          </div>
        <div className="welcome-comp-content-col-2-heading-1">Details heading</div>

        <div className="welcome-comp-content-col-2-points">
            <div><span><img title="icon" src={icon} alt="icon" /></span> <span> Lorem ipsum dolor sit amet.</span></div>
            <div><span><img title="icon" src={icon} alt="icon" /></span> <span> Lorem ipsum dolor sit amet.</span></div>
            <div><span><img title="icon" src={icon} alt="icon" /></span> <span> Lorem ipsum dolor sit amet.</span></div>
            <div><span><img title="icon" src={icon} alt="icon" /></span> <span> Lorem ipsum dolor sit amet.</span></div>
        </div>

        <div className="welcome-comp-content-col-2-text">Secure your child's spot today and let the fun begin! </div>
       
        <div className="welcome-comp-content-col-2-button" >
          <Link to="https://wa.me/+919658139139?text=Hello ! I'm Interested in Memes Service. Please contact me." target="_blank">
Book Seat Now
          </Link>
        </div>

        </div> */}
        
        </div>
      </div>
    </div>
    
    </div>
  );
};

export default HowComponent;
