import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './App.css';
import './css/Header.css';
import './css/Nav.css';
import './css/NumberIncrement.css';
import './css/Slider.css';
import './css/WhyUsComponent.css';
import './css/TopClientComponent.css';
import './css/PriceFlipOne.css';
import './css/PriceComponent.css';
import './css/Footer.css';
import './css/WhyComponent.css';
import './css/HowComponent.css';
import './css/Faq.css';
import './css/MemeCategory.css';
import './css/ContactButtons.css';
import './css/Animation.css';
import './css/KeyWordsComponent.css';

import HomePage from './pages/HomePage';
import OtherPage from './pages/OtherPage';
// import TopClientComponent from './components/TopClientComponent';

const App = () => {
  return (
    <div>

<BrowserRouter>
        <Routes>
          <Route path='/' element={<HomePage />}></Route>
          <Route path='/other' element={<OtherPage />}></Route>

        </Routes>
</BrowserRouter>
      
     
    </div>
  )
}

export default App