import React from 'react'
import CountUp from "react-countup";

const NumberIncrement = () => {
  return (
        <div className="header-numbers" id='about'>
    <div>

        <div className="header-numbers-col">
          <div className="header-numbers-col-inc">
            <CountUp end={5} duration="5" enableScrollSpy />+
          </div>
          <div className="header-numbers-col-txt">
            Years <span><br /></span> experience
          </div>
        </div>

        {/* <div className="header-numbers-col">
          <div className="header-numbers-col-inc">
            <CountUp end={4} duration="4" enableScrollSpy />
            <span>.<CountUp end={9} duration="5" enableScrollSpy /> </span>/5
          </div>
          <div className="header-numbers-col-txt">
            {" "}
            Rating on Google
          </div>
        </div> */}


        <div className="header-numbers-col">
          <div className="header-numbers-col-inc">
            <CountUp end={20000} duration="3" enableScrollSpy />+
          </div>
          <div className="header-numbers-col-txt">Memes<span><br /></span> Served</div>
        </div>
        
        <div className="header-numbers-col">
          <div className="header-numbers-col-inc">
            <CountUp end={10000} duration="3"  enableScrollSpy />+
          </div>
          <div className="header-numbers-col-txt">Happy<span><br /></span> Clients</div>
        </div>
      </div>
    </div>
  )
}

export default NumberIncrement