import React, { useState } from 'react';
import data from './DataCategory.json';

const MemeCategory = () => {
    const [search, setSearch] = useState("")
  return (
    <div>
        <div className="meme-cat">
        <div className="meme-cat-heading">See <span>Meme Poster </span>And <span>Meme Reels</span> Examples</div>
        <div className="meme-cat-search"><input  value={search} type='search' onChange={(e)=>{setSearch(e.target.value)}} placeholder='Search your category ..'/></div>
        <div className="meme-cat-content">
        {/* ? packages.filter((item)=>(item.name.toLowerCase().includes(search.toLocaleLowerCase()))).map((data) => { */}

        <div className='p50'>
            {
                data.filter((e)=>(e.list.toLowerCase().includes(search.toLocaleLowerCase()))).map(e=>(
                        <div className="meme-cat-content-point">{e.list}</div>
                    ))
                }
                </div>
        </div>

        </div>
    </div>
  )
}

export default MemeCategory