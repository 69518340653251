import React, { useState } from "react";

const KeyWordsComponent = () => {
  const [show, setShow] = useState(false);
  return (
    <div>
      <div className="keywords">
        <div className="keywords-head">
          <div className="keywords-head-heading">
            Legendary <span>Laughter</span> Timeless <span>Memes</span>
          </div>
          <div className="keywords-head-icon" onClick={(e) => setShow(!show)}>
            {show ? (
              <i className="fa-regular fa-rectangle-xmark"></i>
            ) : (
              <i className="fa-regular fa-square-caret-left"></i>
            )}
          </div>
        </div>

        {show ? (
          <div className="keywords-content">
            Memes, Funny Memes, Humor Meme, Funny Memes Memes, Funny And
            Hilarious Memes, Meme Generator, Make It Meme, Make A Meme, Make It
            Meme, Make It Meme, Make I Tmeme, Makeameme, Popular Memes, Laugh
            Memes, Memes Com In, Trending Memes, Meme Drake, Memes On The
            Internet, Friends Meme, Best Memes, Best Of The Best Memes, Meme
            Reddit, Memes Viral, Friendship Funny Memes, Memer, Memes Com, New
            Memes, Meme Marketing, Memes In Marketing, Memes Happy, Cute Memes,
            Best Meme Of All Time, Best Friend Memes, Make Your Meme Online,
            Creating Memes Online, Meme Generator Online, Gaming Meme, Joke
            Meme, Meme A Day, Mr Bean Meme, Memes Politics, It Meme, Meme
            Website, Web Meme, Memes About Work, Laugh Funny Memes, Meme Sites,
            Meme Whats, Whatsapp Meme, Meme What
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default KeyWordsComponent;
