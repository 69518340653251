import React from 'react'
import Navbar from '../components/Navbar'
import Header from '../components/Header'
import NumberIncrement from '../components/NumberIncrement'
import WhyUsComponent from '../components/WhyUsComponent'
import SliderComponent from '../components/SliderComponent'
import PriceComponent from '../components/PriceComponent'
import Footer from '../components/Footer'
import WhyComponent from '../components/WhyComponent'
import HowComponent from '../components/HowComponent'
import FaqComponent from '../components/FaqComponent'
import ContactButtons from '../components/ContactButtons'
import KeyWordsComponent from '../components/KeyWordsComponent'

const HomePage = () => {
  return (
    <div>
         <Navbar/>
      <Header/>
      <NumberIncrement/>
      <WhyUsComponent/>
      <SliderComponent/>
      <PriceComponent/>
<HowComponent/>
      <WhyComponent/>
      {/* <MemeCategory/> */}
      <FaqComponent/>
      
      <KeyWordsComponent/>
      
      <Footer/>
      <ContactButtons/>
    </div>
  )
}

export default HomePage