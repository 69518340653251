import React from "react";
import { Link } from "react-router-dom";
// import logo from "../media/images/white-logo.gif";
// import logo from "../media/images/LOL-Legendrs-1.png";
import logo from "../media/images/LOL-Legends-white.png";

const Footer = () => {
  return (
    <div className="footer">
      <div>
        <div className="footer-cols">
          <div className="footer-col-1">
            <div className="footer-col-1-logo">
              {/* LOL Legends */}
              <img title="logo" src={logo} alt="logo" />
            </div>

            <div className="footer-col-1-text">
              Welcome to LOLLegends - The Meme Agency, where humor meets
              innovation to create unforgettable digital experiences.
            </div>
          </div>
          <div className="footer-col-2">
            <div className="footer-col-2-col">
              <div className="footer-col-2-col-heading">INFORMATION</div>
              <div className="footer-col-2-col-points">
                <div>
                  <span>»</span> <a href="#home">Home</a>
                </div>
                <div>
                  <span>»</span> <a href="#price">Our Price</a>
                </div>
                <div>
                  <span>»</span> <a href="#about">About Us</a>
                </div>
                <div>
                  <span>»</span> <a href="#work">Our Work</a>
                </div>
                <div>
                  <span>»</span> <a href="#faq">FAQs </a>
                </div>
              </div>
            </div>

            <div className="footer-col-2-col">
              <div className="footer-col-2-col-heading">CONTACT</div>
              <div className="footer-col-2-col-points">
                <div>
                  <span>
                    <i class="fa-solid fa-location-arrow"></i>
                  </span>{" "}
                  <div>
                    KP2, Q51, Jaypee Greens Wishtown, 
                    <br />
                    Sector 131 Noida 201301
                    
                  </div>
                </div>
                <div>
                  <span>
                    <i class="fa-solid fa-mobile-screen-button"></i>
                  </span>{" "}
                  <Link title="contact" to="tel:+91 9658-139-139" target="_blank">
                    +91 9658-139-139
                  </Link>
                </div>
                <div>
                  <span>
                    <i class="fa-solid fa-envelope"></i>
                  </span>{" "}
                  <div>lollegends@gmail.com</div>
                </div>
                <div>
                  <span>
                    <i class="fa-brands fa-whatsapp"></i>
                  </span>{" "}
                  <Link title="contact"
                    to="https://wa.me/+919658139139?text=Hello ! I'm Interested in Memes Service. Please contact me."
                    target="_blank"
                  >
                    +91 9658-139-139
                  </Link>
                </div>
              </div>
            </div>
            {/* 
      <div className="footer-col-2-col">
      <div className="footer-col-2-col-heading">Join Us</div>
      <div className="footer-col-2-col-points">

      <div className="footer-col-2-col-text">
        Join us to elevate Your Online Presence with Memes.
        <br />
        Crafting Memes to Make Your Brand Go Viral
       </div>
      <div className="footer-col-2-col-form">
        <form>
          <input type="text" placeholder='your number' />
          <button>Join Us</button>
        </form>
      </div>
      </div>
      
      
      </div> */}
          </div>
        </div>
      </div>
      <div className="footer-cc">
        Copyright <i className="fa-regular fa-copyright"></i> 2024, LOL Legends.
        All Rights Reserved.
      </div>
    </div>
  );
};

export default Footer;
