import React from "react";
import icon from "../media/images/correct.png"

const WhyComponent = () => {
  return (
    <div id="why" className="why">
      <div>
        <div className="why-heading">
          Why <span>LOL Legend?</span>
        </div>
        <div className="why-content">
          <div className="why-content-col-1"></div>
          <div className="why-content-col-2">
          {/* <div className="why-content-col-text">
          Why LOL Legends? Because when it comes to meme mastery, we're in a league of our own. Choose us for:
          </div> */}
          <div className="why-content-col-points">
            {/* <div><span><img title="icon" src={icon} alt="icon" /></span>Gaining Physical Strength through Yoga and Dance</div>
            <div><span><img title="icon" src={icon} alt="icon" /></span>Creativity through Art & Craft</div>
            <div><span><img title="icon" src={icon} alt="icon" /></span>Scientific enquiry - Exploration-based activities such as science experiments</div>
            <div><span><img title="icon" src={icon} alt="icon" /></span>Communication Skills through Story Telling and French</div>
            <div><span><img title="icon" src={icon} alt="icon" /></span>Logical skills via using strategies while playing Chess</div>
            <div><span><img title="icon" src={icon} alt="icon" /></span>Gross Motor Skills</div>
            <div><span><img title="icon" src={icon} alt="icon" /></span>Fine Motor Skills</div>
            <div><span><img title="icon" src={icon} alt="icon" /></span>Confidence</div> */}
            





            
            <div><span><img title="icon" src={icon} alt="icon" /></span><span><span><b>Expertise:</b> Our seasoned meme enthusiasts understand internet culture inside out.</span></span></div>
            <div><span><img title="icon" src={icon} alt="icon" /></span><span><span><b>Creativity:</b> We deliver fresh, original memes tailored to your brand.</span></span></div>
            <div><span><img title="icon" src={icon} alt="icon" /></span><span><span><b>Virality:</b> We know how to make memes go viral, maximizing your digital reach.</span></span></div>
            <div><span><img title="icon" src={icon} alt="icon" /></span><span><span><b>Collaboration:</b> We work closely with you to ensure our memes reflect your vision.</span></span></div>
            <div><span><img title="icon" src={icon} alt="icon" /></span><span><span><b>Results:</b> Whether it's brand awareness or engagement, we deliver.</span></span></div>
            
            {/* <div><span><img title="icon" src={icon} alt="icon" /></span>lorem4</div>
            <div><span><img title="icon" src={icon} alt="icon" /></span>lorem4</div> */}

          </div>
          </div>
          <div className="why-content-col-3"></div>
        </div>

        {/* Why us ?

The child after diving into various activities will yield a range of learning outcomes such as-
Gaining Physical Strength through Yoga and Dance, 
Creativity through Art & Craft
Scientific enquiry - Exploration-based activities such as science experiments
Communication Skills through Story Telling and French
Logical skills via using strategies while playing Chess
Confidence 
Gross Motor Skills
Fine Motor Skills */}
      </div>
    </div>
  );
};

export default WhyComponent;
